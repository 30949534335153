<template>
  <div>
    <vx-card title="Les paramètres" class="mb-4">
     <template slot="actions">
        <vx-tooltip text="Retour" color="primary">
          <router-link color="primary" class="ml-2 " :to="{ name: 'Home'}">
            <feather-icon icon="CornerUpLeftIcon" svgClasses="w-10 h-10"></feather-icon>
          </router-link>
        </vx-tooltip>
      </template>
      <div slot="no-body">
        <div v-if="loading" class="ml-2">
          <clip-loader :loading="loading" color="primary"></clip-loader>
        </div>
        <div v-else class="p-8">
          <div>
            <div class="vx-row">
              <div class="vx-col sm:w-1/2 w-full mb-2">
                <vs-input
                  data-vv-validate-on="blur"
                  v-validate="'required'"
                  name="object"
                  label="Object"
                  v-model="form.object"
                  class="w-full"
                />
                <span class="text-danger text-sm">{{
                  errors.first("object")
                }}</span>
              </div>
            </div>

            <div class="mt-4 flex flex-wrap justify-start mb-3">
              <div class="inline-flex">
                <div>
                  <vs-button
                    class="w-full sm:w-auto"
                    :disabled="!validateSendForm || loadingSaveSetting"
                    @click="fetchApplySaveSetting"
                    >Enregistrer</vs-button
                  >
                </div>
                <div class="ml-2">
                  <clip-loader
                    :loading="loadingSaveSetting"
                    color="primary"
                  ></clip-loader>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { en, fr } from "vuejs-datepicker/dist/locale";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import global from "@/config/global";
import { mixin } from "@/mixins/mixin";

export default {
  components: {
    ClipLoader,
  },
  data() {
    return {
      role: {},
      form: {},
      global: {},
      loading: false,
      loadingSaveSetting: false,
      loadingSendSetting: false,
      error: false,
      errorMsg: ""
    };
  },
  created() {
    Validator.localize("fr", VeeValidateFR);
    this.global = global;
  },
  mounted() {
    this.fetchApplyGetSetting();
  },
  computed: {
    ...mapGetters("auth", ["user", "userId"]),

    validateSendForm() {
      return (
        !this.errors.any() &&
        this.form.object != ""
      );
    }
  },
  methods: {
    ...mapActions("general", [
      "applyGetSetting",
      "applyAddSetting",
      "applyPutSetting",
      "applyRemoveSetting"
    ]),
    acceptAlert(color, title, text) {
      this.$vs.notify({
        color,
        title,
        text
      });
    },
    async fetchApplyGetSetting() {
      this.loading = true;
      let data = {
        name: `broker_Setting_${this.userId}`
      };
      try {
        let res = await this.applyGetSetting(data);
        if (res.data.value) this.form = res.data.value;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({
              name: "Login"
            });
          } else {
            this.acceptAlert("danger", "Paramètre", "Une erreur s'est produite");
          }
        }
        this.error = true;
      }
    },

    async fetchApplySaveSetting() {
      if (!this.validateSendForm) return false;
      let data = {
        name: `broker_Setting_${this.userId}`,
        value: { ...this.form}
      };

      this.loadingSaveSetting = true;

      try {
        let res = await this.applyGetSetting(data);

        if (res.data.value) {
         await this.applyPutSetting(data);

          this.loadingSaveSetting = false;
          this.acceptAlert("success", "Paramètre", "Enregistré avec succès");

        } else {
          await this.applyAddSetting(data);

          this.loadingSaveSetting = false;
          this.acceptAlert("success", "Paramètre", "Enregistré avec succès");
        }
      } catch (err) {
        this.loadingSaveSetting = false;
        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({
              name: "Login"
            });
          } else {
            this.acceptAlert("danger", "Paramètre", "Une erreur s'est produite");
          }
        }
        this.error = true;
      }
    }
  }
};
</script>

<style></style>
